import {BASE_URL} from 'src/apiService'
import {SessionStorageKeys} from 'src/enums'

export class DevEnvManager {
  static init() {
    if (!sessionStorage.getItem(SessionStorageKeys.devStore)) {
      sessionStorage.setItem(
        SessionStorageKeys.devStore,
        JSON.stringify({
          baseUrl: BASE_URL,
          initialPath: `${location.origin}${location.pathname}`,
        }),
      )
    }
  }

  static getCurrentBaseUrl() {
    const item = sessionStorage.getItem(SessionStorageKeys.devStore)
    if (item) {
      const content = JSON.parse(item)
      return content['baseUrl']
    }
    return BASE_URL
  }

  static changeBaseUrl(baseUrl: string) {
    const item = sessionStorage.getItem(SessionStorageKeys.devStore)
    if (item) {
      const content = JSON.parse(item)
      content['baseUrl'] = baseUrl
      sessionStorage.setItem(
        SessionStorageKeys.devStore,
        JSON.stringify(content),
      )
      DevEnvManager.cleanStorage()
      window.location.href = content['initialPath']
    }
  }

  static cleanStorage() {
    for (const key in sessionStorage) {
      if (key !== SessionStorageKeys.devStore) {
        sessionStorage.removeItem(key)
      }
    }
  }
}
